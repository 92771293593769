import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import WelcomeUser from './components/WelcomeUser'
import Initiate from './components/Initiate'
import Results from './components/Results'
import CookiePolicy from './components/CookiePolicy'
import PrivacyPolicy from './components/PrivacyPolicy'
import BackupPolicy from './components/BackupPolicy'
import TermsAndConditions from './components/TermsAndConditions'
import NotFoundPage from './components/NotFoundPage';

import { UserStandardProvider } from './contexts/InitiateContexts';
import { SessionProvider } from './contexts/SessionContexts';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './index.scss';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
}

function App() {

  return (
    <div className="App">
      <SessionProvider>
        <UserStandardProvider>
          <BrowserRouter>
            <AnalyticsTracker />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/welcome" element={<WelcomeUser />} />
              <Route path="/:userId/:sessionId/initiate" element={<Initiate />} />
              <Route path="/:userId/:sessionId/results" element={<Results />} />
              <Route path="/cookies" element={<CookiePolicy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/backup-policy" element={<BackupPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="*" element={<NotFoundPage />} />

            </Routes>
          </BrowserRouter>
        </UserStandardProvider>
      </SessionProvider>
    </div>
  );
}

export default App;
