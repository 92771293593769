import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <h1>404 Not Found</h1>
            <p>The requested resource was not found.</p>
        </div>
    );
};

export default NotFoundPage;
