import { React, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Subscribe from '../subcomponents/Subscribe';


const FooterBar = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

return (
    <div>
    <Row>
          <Col xs={12} md={3}>
            <a href="/" className='logo-link'>
              <h1 className="logo-footer" style={{ color: 'white' }}>hoppa</h1>
            </a>
            <a className='footer-link' href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>
            <div className='mt-2'>
              <a className='footer-link' href="https://www.linkedin.com/company/hoppaai/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className='footer-link-header light-purple-font'>Company</div>
            <div className='footer-link enabled'>
            <a className='footer-link' href="/">Home</a>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className='footer-link-header light-purple-font'>Legal</div>
            <div className='footer-link pb-0'><Link to="/privacy-policy" className='footer-link'>Privacy Policy</Link></div>
            <div className='footer-link pb-0'><Link to="/cookies" className='footer-link'>Cookie Policy</Link></div>
            <div className='footer-link pb-0'><Link to="/backup-policy" className='footer-link'>Backup Policy</Link></div>
            <div className='footer-link pb-0'><Link to="/terms-and-conditions" className='footer-link'>Terms & Conditions</Link></div>

          </Col>
          
        </Row>
        <div className='line-divider'>
        </div>
        © 2024 Hoppa Technologies Limited.
        </div>
)
}

export default FooterBar;