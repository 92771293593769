// MODULES
import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
// APP ROUTING
import { useParams } from "react-router-dom";
// CUSTOM COMPONENTS
import HeaderBar from './shared/HeaderBar';
import ResultsStatusBar from './subcomponents/ResultsStatusBar';
import RenameFiles from './subcomponents/RenameFiles';
// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap';
// MATERIAL UI
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Tooltip, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import ResultsTable from './subcomponents/ResultsTable';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL


const Results = () => {
    // User Standard State Variables
    const [userStandard, setUserStandard] = useState(null);
    const [classifierIds, setClassifierIds] = useState([]);
    const [classifierNames, setClassifierNames] = useState([]);
    const [searchTermNames, setSearchTermNames] = useState([]);
    const [tags, setTags] = useState([]);

    // Results State Variables
    const [processedResultsData, setProcessedResultsData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [modifiedFileNames, setModifiedFileNames] = useState({});

    // Page Load State Variables
    const { userId: userId, sessionId: sessionGuid } = useParams();
    const [showSpinner, setShowSpinner] = useState(false)
    const [loadingState, setLoadingState] = useState('Fetching session details...')
    const [loadError, setLoadError] = useState(null)
    const [fileCount, setFileCount] = useState({
        pass: 0,
        warning: 0,
        error: 0,
        fail: 0,
        total: 1
    });
    const [showRenameSettings, setShowRenameSettings] = useState(false);

    // Table Interactions State Variables
    const [showTags, setShowTags] = useState(false);
    const [showSearchTerms, setShowSearchTerms] = useState(false);
    const [selectedValue, setSelectedValue] = useState(''); // State to manage the selected value
    const [editedRow, setEditedRow] = useState(null); // State to store the edited row
    const [userEdits, setUserEdits] = useState(null); // State to store all user edits after they've been applied to the table
    const [unsavedEdits, setUnsavedEdits] = useState(false); // Stage to set the enabled state of the 'Save Edits' button
    const cancelTokenSource = useRef(null);

    // Google analytics ping on component mount
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (unsavedEdits) {
            event.preventDefault();
            event.returnValue = ''; // Standard way to show the confirmation dialog
          }
        };
    
        // Add the event listener when there are unsaved changes
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [unsavedEdits]); // Re-run the effect if `unsavedEdits` changes

    useEffect(() => {

        const handleUpdateRow = async () => {
            if (editedRow) {

                // Call the onSaveRow prop with the edited row
                await handleTableDataChange(Object.values(editedRow));
                console.log('Updating table with', editedRow);

                // Check if editedRow is an object and has keys
                if (!editedRow || typeof editedRow !== 'object' || Object.keys(editedRow).length === 0) {
                    return; // Exit the function if the input is empty or invalid
                }

                // Persisting edits in state for write back to database on user trigger. 
                setUserEdits(prevUserEdits => {
                    const updatedUserEdits = {
                        ...prevUserEdits, // Keep the existing rows
                        ...editedRow      // Add or update rows from editedRow
                    };
                    
                    console.log('Previous user edits:', prevUserEdits);
                    console.log('Updated user edits:', updatedUserEdits);

                    return updatedUserEdits;
                });

                // Clear the edited row state
                setEditedRow(null);
            }
        };

        handleUpdateRow();

    }, [editedRow]);

    // Function to save user edits to code parts and update tableData state to ensure the changes persist
    const handleTableDataChange = (updatedData) => {
        setTableData((prevTableData) => {
            // Map over the existing tableData
            const mergedData = prevTableData.map((row) => {
                // Find the edited row in the updatedData using the name property
                const editedRow = updatedData.find((updatedRow) => updatedRow.name === row.name);
                // If the edited row exists, merge it with the existing row, otherwise, return the existing row unchanged
                return editedRow ? { ...row, ...editedRow } : row;
            });

            return mergedData;
        });
    };

    useEffect(() => {
        // Fetch user standard and results data from the backend
        const fetchResults = async () => {

            let classifiers, searchTerms, tags, classifierIds, classifierNames, searchTermNames

            try {
                setShowSpinner(true);
                const response = await axios.get(
                    `${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${sessionGuid}&folderName=${userId}`
                );
                const userStandardData = response.data; // Ensure the data is resolved here

                // Take user standard json and extract classifiers and search terms
                classifiers = userStandardData?.classifiers || [];
                searchTerms = userStandardData?.enrichers?.searchTerms || [];
                tags = userStandardData?.enrichers?.tags || [];

                // Get classifier names for table headers - these are values that are not needed to be rendered in row / in specific cells
                classifierIds = classifiers.map((classifier) => classifier.id);
                classifierNames = classifiers.map((classifier) => classifier.name);

                searchTermNames = searchTerms.map((searchTerm) => searchTerm.searchTerm)

                // Get classifier names for table headers - these are values that are not needed to be rendered in row / in specific cells
                setClassifierIds(classifierIds);
                setClassifierNames(classifierNames);

                // Get search term names for table headers
                setSearchTermNames(searchTermNames);

                // Get tags for filter list
                setTags(tags);

                setUserStandard(userStandardData);

            } catch (error) {
                console.error("Error fetching userStandard:", error.message);
                setLoadError(error.message); // Handle the error (stop execution, etc.)
            }

            setLoadingState('Loading results...')

            try {
                const response = await axios.get(`${API_BASE_URL}/api/GetResultsTrigger?sessionGuid=${sessionGuid}&folderName=${userId}`);

                const rawResultsData = response.data.results;

                let editsData;
                if (response.data.edits && typeof response.data.edits === 'object' && Object.keys(response.data.edits).length > 0) {
                    // If edits is a non-empty object, retain the original structure
                    editsData = { ...response.data.edits };
                    console.log('Extracted edits data', editsData);
                } else {
                    editsData = {}; // Handle case where edits is empty or not an object by setting it to an empty object
                }

                console.log('Raw results:', rawResultsData)

                const counts = {
                    pass: 0,
                    warning: 0,
                    error: 0,
                    fail: 0,
                    total: response.data.status.session.file_count
                };

                // Loop through the files and count statuses
                for (const status of Object.values(response.data.status.files)) {
                    if (status === 'Pass') {
                        counts.pass++;
                    } else if (status === 'Warning') {
                        counts.warning++;
                    } else if (status === 'Error') {
                        counts.error++;
                    } else if (status === 'Fail') {
                        counts.fail++;
                    }
                }

                // Update state with counts
                setFileCount(prevState => ({
                    ...prevState,  // Retain existing state properties
                    ...counts      // Override with the new counts values
                }))

                // Populate processedResults state with fetched results
                const resultKeys = Object.keys(rawResultsData);
                const processedResultsData = resultKeys.map((resultKey) => {
                    const result = rawResultsData[resultKey];
                    const fileName = modifiedFileNames[resultKey] || resultKey.split('/').pop().split('_results.json')[0];
                    const description = result.description;
                    const classifiers = result.classifiers || [];
                    const extractions = result.extractions || {};
                    const tags = extractions.tags || [];
                    // Check if extractions.searchTerms exists before accessing its properties
                    const searchTerms = extractions && extractions.searchTerms ? extractions.searchTerms : [];

                    // Initialize searchTermObject outside of the loop
                    const searchTermObject = {};

                    // Helper function to flatten nested structures recursively
                    const flattenNestedValue = (value) => {
                        if (Array.isArray(value)) {
                            // Flatten arrays
                            return value.map((item) => flattenNestedValue(item)).join('; ');
                        } else if (typeof value === 'object' && value !== null) {
                            // Flatten nested objects
                            const flattenedObject = [];
                            for (const [key, val] of Object.entries(value)) {
                                flattenedObject.push(`${key}: ${flattenNestedValue(val)}`);
                            }
                            return flattenedObject.join('; ');
                        } else {
                            // Direct values
                            return value;
                        }
                    };

                    // Iterate over each searchTerm object
                    searchTerms.forEach((searchTermObj) => {
                        let searchTerm, value;

                        // Check if the searchTermObj has the "searchTerm" property directly
                        if ("searchTerm" in searchTermObj && "value" in searchTermObj) {
                            searchTerm = searchTermObj.searchTerm;
                            value = searchTermObj.value;
                        } else {
                            // If the searchTermObj does not have "searchTerm" property directly, assume it's the searchTerm and value directly
                            const entries = Object.entries(searchTermObj);
                            if (entries.length === 1) {
                                [searchTerm, value] = entries[0]; // Extract searchTerm and value
                            } else {
                                console.error("Invalid search term object:", searchTermObj);
                                return; // Skip invalid search term object
                            }
                        }

                        // Process and store searchTerm and value
                        const flattenedValue = flattenNestedValue(value);

                        // Check if the documentName already exists as a key in searchTermObject
                        if (!searchTermObject[fileName]) {
                            // If not, initialize it as an empty object
                            searchTermObject[fileName] = {};
                        }

                        // Assign the flattened value to the corresponding documentName and searchTerm
                        searchTermObject[fileName][searchTerm] = flattenedValue;

                        console.log('searchTermObject:', searchTermObject); // Log searchTermObject here
                    });

                    // Map over each classifiers using the code part ID (e.g. IS01) to extract nested results 'data' for each code
                    // Remaining code...
                    // Map over each classifiers using the code part ID (e.g. IS01) to extract nested results 'data' for each code ('data' is a specific object in the results.json)
                    // IMPORTANT the extracted data (code, certainty etc.) are returned as an array, which is nested within the processedResultsData object
                    // this is because the table component cannot handle objects, only arrays
                    // in order to display multiple data points in a single cell it is necessary to pass the cell an array, and then use positional indexing to render the relevant data

                    const classifierResults = classifierIds.map((id) => {
                        const relevantClassifier = classifiers.find(classifier => classifier.id === id);
                        const code = relevantClassifier?.data[0]?.code || '';
                        const certainty = relevantClassifier?.data[0]?.certainty || '';
                        const explanation = relevantClassifier?.data[0]?.explanation || '';

                        // Determine the emoji based on certainty
                        // The emoji is passed along with the certainty string, to provide a more visual way of the user identifying erroneous results
                        let emoji = '';
                        switch (certainty) {
                            case 'low':
                                emoji = ' ⚠️';
                                break;
                            case 'medium':
                                emoji = <span style={{ fontSize: "18px", color: '#FFAA33' }}><b>&nbsp; ✓ </b></span>
                                break;
                            case 'high':
                                emoji = <span style={{ fontSize: "18px", color: '#03C03C' }}><b>&nbsp; ✓</b></span>; // High certainty, tick emoji
                                break;
                            case 'edited':
                                emoji = ' user edited'
                            default:
                                emoji = ''; // Default case, no emoji
                                break;
                        }
                        // classifier data returned as an array
                        return {
                            code: code,
                            certainty: certainty,
                            explanation: explanation,
                            emoji: emoji
                        };
                    });

                    // Map search terms to their corresponding values
                    const searchTermValues = searchTerms.map((searchTermName) => {
                        const relevantSearchTerm = searchTerms.find(searchTerm => searchTerm.searchTerm === searchTermName);
                        return relevantSearchTerm?.value || '';
                    });

                    // Find corresponding searchTermObject for the current document
                    const correspondingSearchTermKey = Object.keys(searchTermObject)[0];
                    const correspondingSearchTermData = searchTermObject[correspondingSearchTermKey];

                    // Merge searchTermObject with other data
                    const mergedData = {
                        name: fileName,
                        description: description,
                        ...Object.fromEntries(
                            classifierNames.map((id, index) => [
                                id,
                                classifierResults[index] || ''
                            ])
                        ),
                        tags: tags.join(', '),
                        ...correspondingSearchTermData // Merge searchTermObject data unconditionally
                    };

                    return mergedData;
                });

                console.log('All processed results data:', processedResultsData);


                // // Set processedResultsData state to ensure there is a master state containing results as they come back from the backend
                // // As new results come in, this table is overwritten
                setProcessedResultsData(processedResultsData)

                // Set tableData state mimics processedResultsTable and exists purely to allow the user to edit while the process hasn't yet completed
                // As new data comes into processedResultsData a check is performed to filter out any files that already exist in tableData
                // New result rows are then added to tableData with existing rows untouched, when the user edits the table component it updates the tableData state

                setTableData(processedResultsData)
                // setTableData(prevTableData => {
                //     // Filter out items from processedResultsData that already exist in prevTableData
                //     const newData = processedResultsData.filter(newItem => !prevTableData || !prevTableData.some(existingItem => existingItem.name === newItem.name));

                // // Map over newData to prepare the new data for insertion
                // // const modifiedData = newData.map(newItem => {
                // //     // Create a copy of the newItem
                // //     const modifiedItem = { ...newItem };
                // //     // Loop through each classifier and keep only the first item
                // //     Object.keys(modifiedItem).forEach(key => {
                // //         if (Array.isArray(modifiedItem[key])) {
                // //             modifiedItem[key] = modifiedItem[key][0]; // Keep only the first item
                // //         }
                // //     });
                // //     return modifiedItem;
                // // });

                // // console.log('Modified data is: ', modifiedData)

                //     // Concatenate the modifiedData with prevTableData to update the table data
                //     return prevTableData ? [...prevTableData, ...newData] : newData;
                // });

                if (editsData) {

                    setEditedRow(editsData)
                    setUnsavedEdits(false)
                }

            } catch (error) {
                console.error('Error fetching results:', error);
            } finally {
                setLoadingState('Finished!')
                setShowSpinner(false)
            }
        };

        fetchResults()

    }, []); // Empty dependency array to run once on mount

    // Define column names to be passed to material-react-table component
    // Note, I tried putting this into the table component for simplicity, with classifiers passed as props but it created and infinite loop and I couldn't work out why
    const columns = useMemo(() => {

        // Columns are generated dynamically from the fields in the 'data' prop passed to the table component (data=tableData)
        // the material react table component takes the data prop and uses the 'accessorKey' to map data to columns
        const dynamicColumns = [
            // This first column is simple, it looks for 'name' in the data prop, which corresponds to file name
            {
                accessorKey: "name",
                header: "File Name",
                enableEditing: false,
                //muiTableHeadCellProps: { sx: { color: "green" } }
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
            },
        ];

        // Conditionally add the newFileName column if it exists in the tableData
        if (tableData && tableData.length > 0 && tableData[0].hasOwnProperty('newFileName')) {
            dynamicColumns.push({
                accessorKey: "newFileName",
                header: "Coded File Name",
                Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
            });
        }

        // The rest of the columns are more complicated; the classifier names are extracted from the 'User Standard' and iterated over
        // The classifier name is then passed as the accessorKey to get the values for that code
        classifierNames.forEach((classifierName, index) => {
            // Get the results data associated with each code part which needs to be unpacked positionally (code, certainty, description, emoji)
            const classifierData = userStandard.classifiers.find(classifier => classifier.name === classifierName);
            dynamicColumns.push({
                accessorKey: `${classifierName}.code`,
                header: classifierName,
                filterSelectOptions: (classifierData && classifierData.data) ?
                    classifierData.data.map(item => ({ value: item.code, label: `${item.code} - ${item.description}` })) : [],
                filterVariant: 'select',
                editVariant: 'select',
                // This section generates the list of pick list for each code part if the user wishes to change the result value.
                // In cases where the IM standard is hierarchical, picklist values are filtered to the allowable list based on the parent.
                Edit: ({ cell, row }) => {

                    let editSelectOptions

                    if (classifierData.isHierarchy) {

                        const parentColumnValue = index > 0 ? row.original[classifierNames[index - 1]].code : null; // Use the previous column based on the index

                        // Filter the options based on the value of the other column
                        editSelectOptions = (classifierData && classifierData.data)
                            ? classifierData.data.filter(item => item.parent === parentColumnValue).map(item => ({
                                value: item.code,
                                label: `${item.code} - ${item.description}`
                            }))
                            : [];

                    } else {
                        editSelectOptions = (classifierData && classifierData.data)
                            ? classifierData.data.map(item => ({
                                value: item.code,
                                label: `${item.code} - ${item.description}`
                            }))
                            : []
                    }

                    return (
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120, maxWidth: '100%' }}>
                            <Select
                                labelId={`${classifierName}-label`}
                                value={cell.getValue()} // Using selectedValue state
                                onChange={(e) => {
                                    setSelectedValue(e.target.value || ""); // Update state

                                    // Update the row's cached value so that it reflects in the table
                                    row._valuesCache[cell.column.id] = e.target.value;

                                    const updatedRow = editedRow && editedRow[row.id]
                                        ? {
                                            ...editedRow[row.id],
                                            [classifierName]: {
                                                ...editedRow[row.id][classifierName],  // Spread the existing classifierName object
                                                code: e.target.value,                  // Update the 'code' field within classifierName
                                            }
                                        }
                                        : {
                                            ...row.original,
                                            [classifierName]: {
                                                ...row.original[classifierName],       // Spread the existing classifierName object
                                                code: e.target.value,                  // Add or update the 'code' field within classifierName
                                            }
                                        };

                                    console.log('Updated row is', updatedRow)

                                    setUnsavedEdits(true)

                                    setEditedRow((prevEditedRow) => ({
                                        ...prevEditedRow,
                                        [row.id]: updatedRow,
                                    }));
                                }}
                                fullWidth // Makes the Select component take the full width of its container
                            >
                                {editSelectOptions.map((item) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                },

                Cell: ({ cell, row }) => {
                    if (!classifierData) {
                        return null; // Return null if classifierData is not found, i.e. if there are no classifier names
                    }

                    const fileName = row.original.name;
                    const cellValue = cell.getValue();

                    // Find the corresponding selectedCodeResults for the current filename
                    const selectedFileResults = tableData.find(item => item.name === fileName);


                    // Check if selectedCodeResults is found
                    if (!selectedFileResults || !selectedFileResults[classifierName]) {
                        return null; // Return null if selectedCodeResults or classifier data is not found for the current filename
                    }

                    // In order to display a tooltip of the description (which is drawn from the user standard), we create a variable that uses the code part from the result as the key to access its properties from the user standard
                    const selectedCodeInformation = classifierData.data.find(item => item.code === cell.getValue());

                    // In order to display info from the results file (certainty and explanation) they need to be mapped to the code part
                    const selectedCodeResults = processedResultsData.find(item => item.name === fileName)[classifierName];
                    const selectedCodeEmoji = selectedCodeResults.emoji;
                    const selectedCodeExplanation = selectedCodeResults.explanation;

                    const isEdited = cellValue !== selectedCodeResults.code;

                    return (
                        <div>
                            <Tooltip title={selectedCodeInformation ? selectedCodeInformation.description : ''} arrow>
                                <span>
                                    <span>{cell.getValue()}</span> {/* Display code */}
                                </span>
                            </Tooltip>

                            {/* Check if the cell value matches the classifier code */}
                            {isEdited ? (
                                // Render "User edited" message and apply styles
                                <span style={{ fontStyle: 'italic', color: 'blue' }}>edited</span>
                            ) : (
                                // Render emoji with tooltip
                                <Tooltip title={selectedCodeExplanation} arrow>
                                    <span>{selectedCodeEmoji}</span>
                                </Tooltip>
                            )}

                        </div>
                    );

                },
            });

        });

        const TagsCell = ({ tags }) => {
            // Split the tags string into an array using comma as the separator
            const tagArray = tags.split(',');

            return (
                <div>
                    {tagArray.map((tag, index) => (
                        <Chip key={index} label={tag.trim()} style={{ marginRight: '5px', marginBottom: '5px' }} />
                    ))}
                </div>
            );
        };

        // Add column for tags
        if (showTags) {
            dynamicColumns.push({
                accessorKey: "tags",
                header: "Tags",
                Cell: ({ cell, row }) => {
                    const fileName = row.original.name;
                    const selectedFileResults = tableData.find(item => item.name === fileName);
                    const tags = selectedFileResults ? selectedFileResults.tags : [];

                    return tags.length > 0 ? <TagsCell tags={tags} /> : null;
                }
            });
        }

        // Add columns for search terms
        if (showSearchTerms) {
            searchTermNames.forEach(searchTermName => {
                dynamicColumns.push({
                    accessorKey: searchTermName,
                    header: <span style={{ color: 'blue' }}>{searchTermName}</span>,
                    Cell: ({ cell, row }) => {
                        const fileName = row.original.name;
                        const selectedFileResults = tableData.find(item => item.name === fileName);
                        const searchResult = selectedFileResults ? selectedFileResults[searchTermName] : [];

                        return (
                            <div>
                                {searchResult &&
                                    <div>
                                        <span>{cell.getValue()}</span>
                                    </div>
                                }
                            </div>
                        );
                    }
                });
            });
        }

        return dynamicColumns;
    }, [userStandard, classifierNames, tableData, showTags, showSearchTerms]);

    const handleToggleTags = () => {
        setShowTags(!showTags);
    };

    const handleToggleSearchTerms = () => {
        setShowSearchTerms(!showSearchTerms);
    };

    // Open the modal
    const handleOpenRenameSettingsModal = () => {
        setShowRenameSettings(true);
    }

    // Close the modal
    const handleCloseRenameSettingsModal = () => {
        setShowRenameSettings(false);
    }

    // Close the modal
    const handleSaveEdits = async () => {

        setShowSpinner(true);
        setLoadingState('Saving edits...')

        const userEditsJSON = JSON.stringify(userEdits);

        const userEditsJSONforBlob = new Blob([userEditsJSON], { type: 'application/json' });

        const formData = new FormData();

        formData.append('file', userEditsJSONforBlob, 'userEdits.json');
        formData.append('folder', userId);

        cancelTokenSource.current = axios.CancelToken.source();

        formData.append('sessionGuid', sessionGuid);

        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;

            console.log("FormData content:", formDataObject);

        })

        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/FileUploadTrigger`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    cancelToken: cancelTokenSource.current.token,
                }
            );

            console.log("File upload successful. Server response:", response.data);

        }
        catch (error) {
            console.error("Error saving edits:", error.message);
            setLoadError(error.message); // Handle the error (stop execution, etc.)

        } finally {
            setShowSpinner(false)
            setUnsavedEdits(false)
        }

    }

    const handleRenameFiles = (codeSettings) => {
        console.log("Rename taking place with following settings: ", codeSettings);
        console.log("Current table data used for rename: ", tableData);
        console.log("List of file names: ", tableData.map(file => file.name));
        console.log("List of classifier names: ", classifierNames);

        // Initialize an object to store the new file names
        const newFileNames = {};

        // Initialize an object to store the sequential numbers for each file name pattern
        const sequentialNumbers = {};

        // Iterate over each file in tableData
        const updatedTableData = tableData.map((file) => {
            let newFileName; // Declare it here for wider scope
            let numberedFileName; // To hold the final file name with numbers

            if (codeSettings.advancedMode) {
                // Start with the advancedMode pattern
                newFileName = codeSettings.advancedMode;

                // Find all placeholders (e.g., {Originator}, {Volume}, {Role}) using regex
                const regex = /\{(.*?)\}/g; // Matches everything inside curly braces

                // Replace placeholders with actual classifier values
                newFileName = newFileName.replace(regex, (match, p1) => {
                    const classifierName = p1.trim(); // Extract the classifier name inside the curly braces

                    let classifierValue;

                    console.log('Name is', classifierName)

                    // First check the string contains code parts,otherwise just return the original string
                    if (classifierName === "File Name") {
                        // Remove the file extension from file.name
                        classifierValue = file.name.split('.').slice(0, -1).join('.'); // Gets the name without extension
                    } else if (classifierName === "Number") {
                        classifierValue = '{Number}'; // You can keep this empty for now
                    } else {
                        classifierValue = file[classifierName]?.code || classifierName; // Get the classifier value from the file data
                    }
                    return classifierValue; // Replace with classifier value or an empty string if not found
                });

    } else {
        // Basic mode logic
        // Extract classifier codes for the current file
        const classifierCodes = classifierNames.map(classifierName => {
            return file[classifierName].code || ''; // If classifier is found, extract code part, otherwise return an empty string
        });

    // Join classifier codes into a single string using a separator
    const separator = codeSettings.documentSeparator;
    newFileName = classifierCodes.join(separator); // Assign the joined string to newFileName
}

// Check if the new file name pattern already exists in the sequentialNumbers object
if (!sequentialNumbers[newFileName]) {
    // Initialize the sequential number for this pattern to 0
    sequentialNumbers[newFileName] = 0;
}

// Increment the sequential number for this pattern
sequentialNumbers[newFileName]++;

// Generate the sequential number string with leading zeros based on the specified number of digits
const sequentialNumberString = sequentialNumbers[newFileName].toString().padStart(codeSettings.numberingSystem.digits, '0');

// Finalize the numbered file name
if (codeSettings.advancedMode) {
    // Replace the {Number} placeholder in the new file name with the sequential number
    numberedFileName = newFileName.replace(/\{Number\}/g, sequentialNumberString);
} else {
    // Combine the new file name pattern and the sequential number
    numberedFileName = `${newFileName}${codeSettings.documentSeparator}${sequentialNumberString}`;
}

// Add the original file name and the corresponding new file name to the newFileNames object
newFileNames[file.name] = numberedFileName;

// Add the new file name to the file object
return { ...file, newFileName: numberedFileName }; // Return the updated file object
        });

// Log the updated tableData
console.log("Updated table data with new file names: ", updatedTableData);

// Update the tableData state with the new file names
setTableData(updatedTableData);

// Update the modifiedFileNames state with the new file names
setModifiedFileNames(newFileNames);
console.log(modifiedFileNames);
    };

const handleDownloadFileFromBlob = async () => {
    try {

        setShowSpinner(true)
        setLoadingState('Zipping files for download...')

        const response = await axios.post(
            `${API_BASE_URL}/api/DownloadDocumentsTrigger?sessionGuid=${sessionGuid}`,
            {
                folderName: userId, modifiedFileNames: modifiedFileNames,
            },
            { responseType: 'arraybuffer' }
        );


        // Check if the response is successful
        if (response.status === 200) {
            // Create a blob containing the files
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'downloadedFiles.zip';

            // Trigger the download
            link.click();

            // Remove the link element only if it's a child of the document body
            if (document.body.contains(link)) {
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
            }

        } else {
            console.error('Failed to fetch files from the blob.');
        }
    } catch (error) {
        console.error('Error downloading files:', error);
    } finally {
        setShowSpinner(false)
    }
};

return (
    <div className="secondary-background">
        <HeaderBar homeLink="/" homeText="hoppa" />
        <div className="full-width-primary-section">
            <ResultsStatusBar
                fileCount={fileCount}
            />
            <Container className='mt-2 rounded-box' style={{ padding: '0', background: 'none', overflowX: 'auto' }}>
                {tableData &&
                    <ResultsTable
                        columns={columns}
                        data={tableData}
                        unsavedEdits={unsavedEdits}
                        handleSaveEdits={handleSaveEdits}
                        onRenameFiles={handleOpenRenameSettingsModal}
                        showTags={showTags}
                        showSearchTerms={showSearchTerms}
                        onToggleTags={handleToggleTags}
                        onToggleSearchTerms={handleToggleSearchTerms}
                        tags={tags}
                        searchTermNames={searchTermNames}
                        onDownloadFiles={handleDownloadFileFromBlob}
                        isModifiedName={modifiedFileNames}
                    />
                }
            </Container>
        </div>
        {showRenameSettings && (
            <RenameFiles onClose={handleCloseRenameSettingsModal} onDownload={handleRenameFiles} />
        )}
        <div className="secondary-background">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: 'flex',
                    flexDirection: 'column', // Align items vertically
                    justifyContent: 'center', // Center vertically
                    alignItems: 'center', // Center horizontally
                }}
                open={showSpinner}
            >
                <CircularProgress color="inherit" />
                <p className='p-2'>{loadingState}</p>
            </Backdrop>
        </div>
    </div>
);
};

export default Results;