import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import FileCard from '../shared/FileCard';
import axios from 'axios';
import { useSession } from '../../contexts/SessionContexts';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const BYTES_PER_KILOBYTE = 1024;
const convertBytesToKB = (bytes) => Math.round(bytes / BYTES_PER_KILOBYTE);

function FileUpload({handleSetToastMessage, handleSetFileCount, handleSetNextLock}) {
  const { sessionGuid, userId } = useSession();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openCall, setOpenCall] = useState(false)
  const [fileCount, setFileCount] = useState({ countCompleted: 0, countProcessing: 0, countFailed: 0 });

  useEffect(() => {
    console.log('Current files are', uploadedFiles);

    const counts = {
      countCompleted: uploadedFiles.filter(file => file.status === 'completed').length,
      countProcessing: uploadedFiles.filter(file => file.status === 'processing').length,
      countFailed: uploadedFiles.filter(file => file.status === 'failed').length
    };

    setFileCount(counts);

    handleSetFileCount(counts.countCompleted)

    console.log('In component file count is', uploadedFiles.length)

    if (counts.countProcessing === 0) {
      handleSetNextLock(false)
    } else if (!uploadedFiles) {
      handleSetNextLock(true)
    }
  }, [uploadedFiles]);

  const fileUpload = (files) => {

    console.log('Session GUID is', sessionGuid)
  
    handleSetNextLock(true)

    // Check for duplicate files. 
    const uniqueFiles = files.filter(file => !uploadedFiles.some(upFile => upFile.name === file.file.name));
    if (uniqueFiles.length < files.length) {
      const skippedFiles = files.length - uniqueFiles.length
      handleSetToastMessage(`Duplicate files found. ${skippedFiles} files skipped.`);
    }

    console.log('New files are', uniqueFiles)

    const filesWithSize = uniqueFiles.map(file => ({
      name: file.file.name,
      sizeKB: convertBytesToKB(file.file.size),
      status: 'new'
    }));

    console.log('Files with size are', filesWithSize)

    setUploadedFiles([...uploadedFiles, ...filesWithSize]);

    // Attempt file upload
    for (let i = 0; i < uniqueFiles.length; i++) {

      const file = uniqueFiles[i].file

      console.log('File to be uploaded is', file)

      // Update the status to 'processing'
      setUploadedFiles(prevUploadedFiles => {
        return prevUploadedFiles.map((uploadedFile) => {
          if (uploadedFile.name === file.name) {
            return {
              ...uploadedFile,
              status: 'processing'
            };
          }
          return uploadedFile;
        });
      });

      console.log("Attempting to upload file:", file);

      const formData = new FormData();
      
      // Append the single file to the formData with the key 'file'
      formData.append('file', file);
      formData.append('folder', userId );
      formData.append('sessionGuid', sessionGuid);

      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      
      console.log(FormData)

      let percentCompleted = 0

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: function(progressEvent) {
          percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
          console.log( `${progressEvent.loaded}kb of ${progressEvent.total}kb | ${percentCompleted}%` )
        }
      };

      axios.post(
        `${API_BASE_URL}/api/FileUploadTrigger`,
        formData,
        config
      )
      .then((response) => {
        console.log("Upload successful. Server response:", response.data);
        // Update the uploaded file status to 'completed'
        setUploadedFiles(prevUploadedFiles => {
          return prevUploadedFiles.map((uploadedFile) => {
            if (uploadedFile.name === file.name) {
              return {
                ...uploadedFile,
                status: 'completed'
              };
            }
            return uploadedFile;
          });
        });
      })
      .catch((error) => {
        console.error("Error during upload:", error);

        handleSetToastMessage('Error during file operation.')

        // Update the uploaded file status to 'failed'
        setUploadedFiles(prevUploadedFiles => {
          return prevUploadedFiles.map((uploadedFile) => {
            if (uploadedFile.name === file.name) {
              return {
                ...uploadedFile,
                status: 'failed'
              };
            }
            return uploadedFile;
          });
        });
      });
      
      setUploadProgress(0);
    }

    setSelectedFiles([]); // Clear selectedFiles after processing
  };

  const handleDeleteFile = (fileName) => {

    console.log('Attemping to delete', fileName)

    setOpenCall(true)

    // Find the file with the specified fileName in uploadedFiles
    const fileToDelete = uploadedFiles.find(file => file.name === fileName);

    if (fileToDelete && fileToDelete.status === 'failed') {
      // File with 'failed' status found, proceed with deletion from the frontend only. 

      // Remove the file from uploadedFiles
      const updatedFiles = uploadedFiles.filter((file) => file.name !== fileName);
      setUploadedFiles(updatedFiles);

      setOpenCall(false);

      return
    }

    // Else continue with post to Fuction app. 
    const headers = {
      'Content-Type': 'application/json', // Set the Content-Type header to JSON
      // Add any other headers as needed
    };

    // Send POST request to backend API for file deletion
    axios.post(`${API_BASE_URL}/api/DeleteFileTrigger?sessionGuid=${sessionGuid}`, {
      fileNames: fileName, folder: userId, sessionGuid: sessionGuid
    }, { headers }
    )
      .then((response) => {
        console.log("Delete successful. Server response:", response.data);

        console.log('Files before updating the array', uploadedFiles)

        setUploadedFiles(prevUploadedFiles => {
          const updatedFiles = prevUploadedFiles.filter((file) => file.name !== fileName);
          return updatedFiles;
        })
        console.log('Files after updating the array', uploadedFiles)
        setOpenCall(false)

        // // Update the file count after deletion
        // handleSetFileCount(countCompletedFiles())
      }
      )
      .catch((error) => {

        console.error("Error during delete. Server response:", error);

        handleSetToastMessage('Error during file operation.');

        setUploadedFiles(prevUploadedFiles => {
          const updatedFiles = prevUploadedFiles.filter((file) => file.name !== fileName);
          return updatedFiles;
        })
        setOpenCall(false)

        // // Update the file count after deletion
        // handleSetFileCount(countCompletedFiles())
      })
  };

  const traverseFileTree = async (item, updatedFiles, parentFolderName = '') => {
    if (item.isFile) {
      const file = await new Promise((resolve) => item.file(resolve));
      updatedFiles.push({ file, folder: parentFolderName });
    } else if (item.isDirectory) {
      const directoryReader = item.createReader();
      const folderName = parentFolderName ? `${parentFolderName}/${item.name}` : item.name;

      const entries = await new Promise((resolve) => directoryReader.readEntries(resolve));

      for (let j = 0; j < entries.length; j++) {
        await traverseFileTree(entries[j], updatedFiles, folderName);
      }
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault()
    const files = Array.from(e.target.files);
    const updatedFiles = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      updatedFiles.push({file, folder: ''})

    fileUpload(updatedFiles);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const droppedItems = e.dataTransfer.items;
    const updatedFiles = [];

    for (let i = 0; i < droppedItems.length; i++) {
      const item = droppedItems[i].webkitGetAsEntry();

      if (item) {
        await traverseFileTree(item, updatedFiles);
      }
    }
    fileUpload(updatedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setSelectedFiles([]); // Clear selectedFiles after processing
  };

  return (
    <div>
      <Container className="rounded-box pt-3">
      <div className="dashed-box mt-0" onDrop={handleDrop} onDragOver={handleDragOver}>
          <div className="file-upload-drop-area">
            <FontAwesomeIcon icon={faCloudArrowUp} className="fa-3x mb-2" style={{ color: 'silver' }} />
            <p>
              Drag and drop a folder here or{' '}
              <label htmlFor="file-upload-input" className="file-upload-label">
                <u>choose files</u>
              </label>
            </p>
            <input
              key={uploadedFiles.length} // Reset the key whenever uploadedFiles change
              type="file"
              id="file-upload-input"
              className="file-upload-input hidden"
              onChange={handleFileChange}
              multiple
            />
          </div>
        </div>
        <div>Total: {uploadedFiles.length} files ({fileCount.countCompleted} completed, {fileCount.countProcessing} processing, {fileCount.countFailed} failed)</div>
        <div className="file-list">
          {/* Display uploaded files using FileCard component */}
          {uploadedFiles.map((file, index) => (
            <FileCard
              key={index}
              fileName={file.name}
              fileType={file.name.split('.').pop()}
              total={file.sizeKB+'KB'}
              status = {file.status}
              uploadProgress={uploadProgress}
              handleDelete={handleDeleteFile} // Pass handleDeleteFile function to handle file deletion
            />
          ))}
        </div>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCall}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default FileUpload;
